.card-hover-pointer {
  cursor: pointer;
}

.background-color-wait {
  background-color: #ffa800 !important;
}

.background-color-expired {
  background-color: #f64e60 !important;
}

.background-color-used {
  background-color: #1bc5bd !important;
}

.background-color-recharge {
  background-color: #009EF7 !important;
}

.max-height-service-secsion {
  max-height: 500px;
  overflow-y: auto;
}

.background-color-error {
  background-color: #ff3333 !important;
}

.loading {
  position: fixed;
  width: 10%;
  height: 10%;
  opacity: 1.0;
  z-index: 9999;
  top: 50%;
  left: 50%
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.width-100 {
  width: 100% !important;
}

.DayPickerInput input {
  background-color: #F5F8FA;
  border: 0px solid;
  height: 40px;
  width: 155px;
  border-radius: 7px;
  text-align: center;
}

.min-height-500px {
  min-height: 500px !important;
}

.api-doc-header-summary-button {
  background: #61affe;
  border-radius: 3px;
  color: #fff;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 0;
  display: inline-block;
  width: 100px;
  text-align: center;
  text-shadow: 0 1px 0 rgb(0 0 0 / 10%);
}

.api-doc-header-summary-title {
  align-items: center;
  color: #3b4151;
  font-family: monospace;
  font-size: 16px;
  font-weight: 600;
  padding: 0 10px;
  word-break: break-word;
}

.vertical-align-top {
  vertical-align: top;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.background-color-expired-toast {
  background-color: #f64e60 !important;
  width: 100%;
}

.background-color-success-toast {
  background-color: #47BE7D !important;
  width: 100%;
}

.muted-background-color {
  background-color: #F5F8FA !important;
}

.fv-plugins-message-container {
  color: red;
}

.width-100px {
  width: 100px;
}

.width-fix-content {
  width: fit-content;
}

.background-color-table-row {
  background-color: aliceblue;
}

.font-weight-bold {
  font-weight: bold;
}

.border-mail {
  border: solid 1.5px #68bbe3 !important;
  border-radius: 10px;
}

.text-green {
  color: #1bc5bd;
}

.card-header {
  background-color: #fff;
  border-bottom: 1px solid #eff2f5;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  border-radius: 0.475rem !important;
}

.profile-image .ant-upload.ant-upload-select {
  display: contents !important
}
